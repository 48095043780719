import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

export const AffectionsPageTemplate = ({ title, introtext, edges, html }) => {
	return (
		<>
			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: introtext,
								}}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="section">
				<div className="container">
					<div className="content">
						<h2>Meine Behandlungsschwerpunkte sind:</h2>
						<div className="columns">
							<div className="affections-grid columns is-multiline">
								{edges.map(({ node }) => {
									const { title, icon, description } = node.frontmatter
									const { slug } = node.fields
									return (
										<a
											key={title}
											href={slug}
											className="affections-area column is-6"
										>
											<section className="section whitepanel">
												<div className="has-text-centered">
													<div className="affection-area-icon">
														{icon && (
															<img
																className="margin-top-0"
																alt={`Icon für ${title}`}
																src={
																	!!icon.childImageSharp
																		? icon.childImageSharp.fluid.src
																		: icon.publicURL
																}
															></img>
														)}
													</div>
												</div>
												<h4>{title}</h4>
												<p>{description}</p>
											</section>
										</a>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: html,
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

AffectionsPageTemplate.propTypes = {
	title: PropTypes.string,
	introtext: PropTypes.string,
	// items: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		title: PropTypes.string,
	// 		text: PropTypes.string,
	// 		image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// 	})
	// ),
}

const AffectionsPage = ({ data }) => {
	console.log(`AffectionsPage data`, data)

	const { frontmatter, html } = data.markdownRemark
	const { edges } = data.allMarkdownRemark

	return (
		<Layout>
			<AffectionsPageTemplate {...frontmatter} html={html} edges={edges} />
		</Layout>
	)
}

AffectionsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
}

export default AffectionsPage

export const AffectionsPageQuery = graphql`
	query AffectionsPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "affections-page" } }) {
			html
			frontmatter {
				title
				introtext
			}
		}
		allMarkdownRemark(
			filter: { fields: { slug: { glob: "/beschwerden/*" } } }
		) {
			edges {
				node {
					frontmatter {
						title
						description
						icon {
							childImageSharp {
								fluid(maxWidth: 1520, quality: 94) {
									...GatsbyImageSharpFluid
								}
							}
							publicURL
						}
					}
					fields {
						slug
					}
				}
			}
		}
	}
`
